.rdtDay:not(.rdtDisabled):not(.rdtNew):not(.rdtOld) {
  background-color: aquamarine;
}

.doctor-box .custom-btn {
  background-color: #18c0f2 !important;
  border-color: #18c0f2 !important;
  text-transform: capitalize;
  width: 100%;
  padding: 0 !important;
}

.doctor-box .custom-dropzone {
  display: flex;
  border: 2px solid #18c0f2;
  border-radius: 6px;
  overflow: hidden;
}

.doctor-box .custom-dropzone .btn {
  background-color: #18c0f2 !important;
  border-color: #18c0f2 !important;
  width: 25%;
}

.doctor-box .custom-dropzone .inputFile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.doctor-box .custom-dropzone .inputFile:hover {
  background-color: #d3f5ff;
}

.doctor-box .custom-dropzone .inputFile span {
  position: absolute;
  margin: 0 auto;
  font-weight: 600;
}

.doctor-box .custom-dropzone .inputFile input {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.btn-primary.disabled,
.btn-primary:disabled {
  cursor: not-allowed;
}

.form-control.error {
  border-color: #dc3545;
}

.select.error .css-yk16xz-control,
.select.error .css-1pahdxg-control:hover {
  border-color: #dc3545 !important;
}

.select.error .css-1pahdxg-control {
  border-color: #dc3545 !important;
  box-shadow: 0 0 0 1px #dc3545 !important;
}

.form-control.error:focus {
  border-color: #dc3545;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #dc354585;
}
.qr-code-doc {
  float: right !important;
}
.doc-title {
  position: relative;
  top: 50px;
}
.custom-dropzone a {
  font-size: 1rem !important;
  line-height: 1.5 !important;
  color: #ffffff !important;
}
.variable-fees-table {
  padding-bottom: 20px;
}
.box-bottom-padding {
  padding-bottom: 60px;
}
.hospitalcode-err {
  color: red;
}
.table-data-bold {
  font-weight: bolder !important;
}
.price:before {
  padding-right: 10px !important;
  color: black;
  font-weight: 700;
}
.uploaded-image {
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 15px;
}
.center {
  text-align: center;
}

.chat-window-appointment{
  height: 60vh;
  max-height: 60vh;
  overflow: scroll;
}