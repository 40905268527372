html,
body,
body > div {
  margin: 0;
  padding: 0;
}

h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

/* li {
    margin: 1.5em 0;
    padding: 0;
} */

b {
  /* used for event dates/times */
  margin-right: 3px;
}

.full-calendar {
  /* display: flex; */
  /* min-height: 100%; */
  /* font-family: Arial, Helvetica Neue, Helvetica, sans-serif; */
  /* font-size: 14px; */
}

.full-calendar-sidebar {
  width: 200px;
  line-height: 1.5;
  background: white;
  border-right: 0px solid #d3e2e8;
}

.full-calendar-sidebar-section {
  padding: 1em;
}

.full-calendar-main {
  flex-grow: 1;
  padding: 3em;
}

.fc {
  /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}

/* custom */
.hide-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fc-event-title {
  color: white;
}

.fc-daygrid-dot-event:hover,
.fc-daygrid-dot-event.fc-event-mirror {
  background-color: #349ed3 !important;
}

.fc-event,
.fc-event-dot {
  background-color: #1d3041 !important;
}
