.content {
  padding-top: 60px;
  padding-bottom: 0px;
}
.page-header.profile-header {
  /* background-color: white; */
}
.content.profile-content {
  background-color: white;
}
.content .content {
  padding: 0;
}

.appointments {
  padding-bottom: 60px;
  text-align: end;
}

.appointments .btn.appoint-btn {
  border-radius: 35px;
  padding: 6px 12px;
  border-color: #26caf3;
  background-color: #26caf3;
  color: #fff;
  font-weight: 600;
  transition: all 0.2s linear;
  font-size: 15px;
}

.main-nav.nav > li > a {
  padding: 0px 10px;
  font-weight: 500;
  /* border-radius: 50px; */
  text-decoration: none;
  /* font-size: 15px; */
}

/* ==================== Changes for navigation color on hover ======================= */

.nav-item .active {
  /* background-color: #50d8fa;
  color: #fff !important; */
  color: #0f0f0f !important;
}
.appointments {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.btn.appoint-btn {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: fit-content;
  justify-content: end;
}

.btn.btn-primary.appoint-btn:hover {
  background-color: #50d8fa;
  color: white;
}

/* doctor section */
.doct-info {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.doct-text {
  margin-left: 10px;
  color: #3a3939;
  font-size: 1.25rem;
  font-weight: 500;
}

/* input */

/* input[type="file"] {
  background-color: #0062cc;
} */

.navbar-nav {
  align-items: center;
}

.nav-patient .nav-item .btn {
  align-self: center;
  padding: 5px 10px;
}

/* .nav-patient .nav-item .nav-link{
    padding: 4px 10px;
}

.nav-patient .nav-item .nav-link:hover{
    padding: 4px 9px;
    background-color: white;
    color: #50d8fa;
    border: 1px solid #50d8fa;
} */

.navbar-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.departments {
  background-color: rgb(251, 251, 251);
}

@media only screen and (max-width: 992px) {
  .navbar-head {
    display: none;
  }
}

.nav-patient .nav-item .btn.logout {
  border-radius: 50px;
  padding: 4px 10px;
  margin-left: -5px;
  font-size: 14px;
  background-color: #0062cc;
  color: white;
  border-color: #0062cc;
}

.appointment-cards a {
  text-decoration: none;
  color: inherit;
}

.appointment-cards a:hover,
.appointment-cards a:focus {
  color: black;
  outline: none;
  text-decoration: none;
}

.hide-eye-icon {
  display: none;
}
.pass-input {
  position: relative;
}

.eye-icon {
  position: absolute;
  top: 58%;
  right: 5%;
}

/* search */
.nav-link-search {
  text-decoration: none;
  color: black;
}

.search-cont {
  list-style: none;
}

.search-bar {
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  justify-content: start;
}
.iconify-app {
  color: #3493a9;
  height: 30px;
  width: 30px;
}

.search-location {
  display: block i !important;
}
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 40vw;
  margin-bottom: 2%;
  height: 40px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.location-bar {
  width: 20%;
}

@media only screen and (max-width: 768px) {
  .react-autosuggest__input {
    width: 50vw;
  }

  .location-bar {
    width: 30%;
  }
}

@media only screen and (max-width: 514px) {
  .react-autosuggest__input {
    width: 65vw;
  }

  .location-bar {
    width: 30%;
  }
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: 51px;
  width: 300px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
}

.react-autosuggest__suggestion--focused {
  background-color: #0c7eaf;
  color: #fff;
}

.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}

.dancounsell {
  background-image: url(https://s3.amazonaws.com/uifaces/faces/twitter/dancounsell/48.jpg);
}

.ladylexy {
  background-image: url(https://s3.amazonaws.com/uifaces/faces/twitter/ladylexy/48.jpg);
}

.mtnmissy {
  background-image: url(https://s3.amazonaws.com/uifaces/faces/twitter/mtnmissy/48.jpg);
}

.steveodom {
  background-image: url(https://s3.amazonaws.com/uifaces/faces/twitter/steveodom/48.jpg);
}

.name {
  margin-left: 68px;
  line-height: 45px;
}

.highlight {
  color: #ee0000;
  font-weight: bold;
}

.react-autosuggest__suggestion--focused .highlight {
  color: #120000;
}

@media only screen and (max-width: 992px) {
  .search-bar {
    margin-top: 12%;
  }
}
@media only screen and (max-width: 768px) {
  .search-bar {
    margin-top: 4%;
  }
}
@media only screen and (max-width: 536px) {
  .search-bar {
    margin-top: 5%;
  }
}
@media only screen and (max-width: 514px) {
  .search-bar {
    margin-top: 5%;
  }
}

/* patient */

.patient-img {
  margin-top: 0%;
}

@media only screen and (max-width: 576px) {
  .patient-img {
    margin-top: 5% !important;
  }
}

/* login */

.login-row {
  margin: 10%;
}
.login-outer {
  border: solid 1px rgba(216, 216, 216, 0.5);
  border-radius: 4px;
  /* font-family: "Camphor"; */
  color: #787887;
}
.loginForm {
  padding: 8%;
}
.sub-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 4%;
  border-bottom: 1px solid rgba(216, 216, 216, 0.5);
}
#loginlink {
  display: inline-block;
  color: #14bef0;
  font-weight: bold;
  padding: 10px 40px 10px 40px;
}

#registerlink {
  display: inline-block;
  color: #14bef0;
  font-weight: bold;
  padding: 10px 40px 10px 40px;
}

#login-reg {
  display: inline-block;
  color: #555858;
  font-weight: bold;
  padding: 10px 40px 10px 40px;
}

#reg-login {
  display: inline-block;
  color: #555858;
  font-weight: bold;
  padding: 10px 40px 10px 40px;
}

.login-info {
  padding: 2% !important;
}

.sub-link {
  border-bottom: 3px solid #14bef0;
  height: 100%;
}

@media only screen and (max-width: 992px) {
  .login-row {
    margin: 18%;
  }
  .login-outer {
    padding: 5%;
  }
  .sub-header {
    margin-top: 5%;
  }
}

@media only screen and (max-width: 768px) {
  .sub-header {
    margin-top: 5%;
  }
}

.card {
  border-color: #f2f2f2 !important;
  cursor: pointer;
  border-radius: 0 !important;
  transition: all 0.2s linear;
  color: #333;
}

.appointment-cards .card {
  cursor: pointer;
}

.card:hover {
  -webkit-box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.2);
  color: #333;
}

.card .date {
  min-width: 150px;
  width: 100%;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 0;
}

.card .date p {
  height: 100%;
  width: 100%;
  padding: 5px;
  margin: 0;
  background-color: #e5e5e5;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card .card-title {
  margin-bottom: 0;
}

.card .card-text.appointment-type p {
  margin: 10px 0;
}

.apt-btn {
  text-align: center;
  margin: 5px auto;
}

.patient-card {
  cursor: default;
}
.patient-card:hover {
  -webkit-box-shadow: auto;
  box-shadow: auto;
}
.patient-card .card-text.appointment-type .btn svg {
  margin-top: -2px;
}

.patient-card .patient-name {
  display: flex;
  justify-content: space-between;
}

.patient-card .patient-name p {
  font-size: 18px;
}

.patient-card .card-header {
  padding: 30px;
  padding-bottom: 25px;
}

.patient-card .card-header p {
  font-size: 18px;
  margin-bottom: 0;
}

.patient-card hr {
  margin: 0 auto !important;
  width: 95%;
}

.dropdown .dropdown-menu .dropdown-list .dropdown-item:hover,
.dropdown .dropdown-menu .dropdown-list .dropdown-item:focus {
  background-color: inherit;
  color: inherit;
}

.dropdown .dropdown-menu .dropdown-list {
  list-style: none;
  padding: 0;
}

.content {
  background-color: #fff;
}
.appointment-time {
  margin: 0;
  margin-top: -10px;
  margin-bottom: 5px;
}

.appointment_status {
  text-transform: capitalize;
  margin: 0;
  font-size: 19px;
  color: #0554a8;
}

.breadcrumb {
  width: fit-content;
  margin-left: auto;
  border-radius: 35px !important;
  background-color: white;
}

.reports .report {
  margin-top: 10px;
}

.reports .report div {
  background-color: #e5e5e5;
  min-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-title-h {
  display: flex !important;
  justify-content: space-between !important;
}

.btn.custom-btn {
  font-weight: 500;
  font-size: 17px;
  font-family: "Poppins";
  text-transform: capitalize;
  padding: 2px 10px;
}

.btn.custom-btn.btn-primary {
  background-color: #50d8fa;
  border-color: #50d8fa;
}

.sub-title-h .custom-btn.btn-primary {
  padding: 2px 10px;
  border-radius: 50px;
}

.doctor-name {
  font-size: 20px;
}

.experience-widget-h {
  margin-bottom: 37px;
}

.fa-blue {
  color: #0554a8;
}

.card-title.title {
  margin-bottom: 3px;
}

.card-text.text {
  margin-bottom: 25px !important;
}

.edit-profile {
  font-family: "Poppins" !important;
}

.edit-profile .page-title.about-doctor {
  text-align: center;
  font-size: 27px;
}

.edit-profile .form-control {
  border-radius: 0 !important;
  box-shadow: none;
  padding: 0.469rem 0.75rem !important;
  border-color: #eaeaea;
  font-size: 14px !important;
  min-height: 40px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.edit-profile .form-control:disabled,
.form-control[readonly] {
  cursor: not-allowed;
}

.edit-profile .form-control:focus {
  border-color: #50d8fa;
  box-shadow: none;
  outline: 0 none;
  color: #495057;
  background-color: #fff;
}

.team .dept-box2 h3 {
  font-size: 23px;
}
.team .bod {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.login-signup-redirect-link {
  font-size: 15px !important;
  font-weight: 500;
  color: #4b76a9;
}
.zNoeh {
  margin: 0px !important;
}
.header-top .container {
  margin-left: 30px !important;
  margin-right: 25px !important;
}
@media (min-width: 1200px) {
  .header-top .container,
  .header-top .container-lg,
  .header-top .container-md,
  .header-top .container-sm,
  .header-top .container-xl {
    max-width: -webkit-fill-available;
  }
}
.jivIzz {
  /* margin-bottom: 10px !important; */
  position: absolute;
  /* align-items: center; */
  /* right: 170px; */
  border-radius: 3px !important;
  height: 35px;
}
.jivIzz > .wrapper {
  border-radius: 6px !important;
  border-color: #b4b4be !important;
}

.gJTISH {
  min-height: 35px !important;
}

.cxCvcx {
  /* background-color: #0062cc !important; */
  margin-top: 10px !important;
  /* color: red; */
}

.otphead {
  text-align: center !important;
  width: 100%;
}

/* new search bar */
/* input {
  margin: 0;
  width: 20rem;
  padding: 1rem 0.5rem;
} */

.downshift-dropdown {
  margin: 0 auto;
  width: 20rem;
  border: 1px solid whitesmoke;
  border-bottom: none;
}

.dropdown-item {
  padding: 0.5rem;
  cursor: pointer;
  border-bottom: 1px solid whitesmoke;
  font-size: 1rem;
  text-align: left;
}

.dropdown-head {
  padding: 0.5rem;
  margin-left: 19px;
  cursor: pointer;
  border-bottom: 1px solid whitesmoke;
  font-size: 1rem;
  text-align: left;
}

.dropdown-button {
  padding: 0.6rem;
  border-radius: 3px;
  background: white;
  cursor: pointer;
}

.popper-div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}

.popper-item {
  padding: 0.5rem;
  border-bottom: 1px solid whitesmoke;
}
.appointment2-btn {
  font-size: 14px !important;
  background-color: #31d4eb !important;
  border-color: #31d4eb !important;
}
.display-picture {
  border-radius: 100%;
}
.card-title2 {
  color: #31d4eb !important;
  font-size: 25px !important;
  margin-top: auto;
  font-weight: lighter;
}
.speciality {
  font-size: 13px !important;
  color: #787897;
  font-weight: lighter !important;
  margin-bottom: 5px !important;
}
.location {
  font-size: 13px !important;
  color: black;
  font-weight: bold !important;
  margin-bottom: 5px !important;
}
.required {
  color: red;
}
.available {
  margin-bottom: 5px !important;
  font-size: 13px;
}
.card-end {
  font-size: 14px !important;
}
.account-btn {
  color: white !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  cursor: not-allowed;
}

.form-control.error {
  border-color: #dc3545;
}

.form-control.error:focus {
  border-color: #dc3545;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #dc354585;
}

.search-container {
  float: right;
}

.search-container form {
}

.search-container input[type="text"] {
  flex: 1;
  padding: 7px 7px;
  font-size: 15px;
  border: 1px solid #ccc;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.search-container button {
  float: right;
  padding: 6px 10px;
  margin-right: 16px;
  background: #dddcdc;
  font-size: 17px;
  border: none;
  cursor: pointer;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.search-container button:hover {
  background: #ccc;
}

.appointment-available {
  text-align: center;
}

.appointment-available .appointment2-btn {
  float: right;
}

@media screen and (max-width: 600px) {
  .search-container {
    float: none;
  }
  .search-container a,
  .search-container input[type="text"],
  .search-container button {
    float: none;
    display: block;
    text-align: left;
    /* width: 100%; */
    margin: 0;
    padding: 14px;
  }
  .search-container input[type="text"] {
    border: 1px solid #ccc;
  }

  .card .date {
    padding-right: 20px;
  }
}

.phone.countryCode {
  margin-right: 10px;
  position: relative;
}

input#contryCode {
  padding: 5px 1px 5px 17px;
}

.phone.countryCode::before {
  content: "+";
  position: absolute;
  top: 7px;
  left: 5px;
}

img.Profile_pic {
  padding: 3px;
  width: 100%;
  border: 1px solid #d6d9db;
  margin-bottom: 10px;
  margin-top: 10px;
}

.doctor-inner img {
  width: 100%;
}

.edit-profile .MuiInputBase-root {
  border: 1px solid #ced4da;
  padding: 3px;
}

.top-section {
  padding: 20px 130px;
}
.doctor-name-presc {
  margin-bottom: 0px !important;
}
.doctor-number-presc {
  color: #777777;
  margin-bottom: 0;
}

.main-compi {
  width: 100%;
  display: table;
}
.second-compi {
  display: table-row;
  height: 100px;
  margin: 0 30px !important;
}
.third-compi {
  width: 30%;
  display: table-cell;
  padding: 0 30px;
}
.fourth-compi {
  display: table-cell;
}

.accordian-section {
  display: table-cell !important ;
  width: 50% !important;
  padding-right: 5px;
}
.diagnosis-section {
  display: table-cell !important;
  width: 50% !important;
  /* padding: 0px !important; */
  padding-left: 5px;
}
.vl {
  border-left: 2px solid #ebeef4;
  height: 60px;
  max-height: fit-content;
  position: relative;
  top: 40px;
  margin-left: 30px;
  margin-right: 30px;
}
.first-content {
  display: table-row;
  height: 100px;
}
.first-content li {
  display: table-cell;
  height: 100px;
}
.doctor-detail-presc {
  color: #777777;
  font-size: 18px !important;
  text-align: center;
}
.doctor-detail-value {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  text-align: center;
}
.second-component {
  background-color: #f7f8f9;
  /* padding: 40px 150px; */
}
.appointment-details {
  padding-left: 20px;
  padding-right: 20px;
}
.diagnosis-title {
  font-size: 0.9375rem;
  font-weight: 400;
  padding: 10px 10px 0 10px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  color: #46b9d6 !important;
}
.diagnosed-card {
  border-radius: 5px !important;
}
.diagnosis-info {
  padding: 10px 10px 0 10px;
}
.diagnosis-hr {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.above-ul {
  padding: 20px 0px 20px 100px !important;
}
.qr-code {
  width: 200px;
}
.chat-icons {
  font-size: 25px;
  /* margin-right: 10px; */
  /* position: relative;
  bottom: 11px; */
}
.comment-area {
  height: 40px;
  margin-left: 10px;
  border-radius: 50px;
  width: 80%;
  border-color: #cccccc;
  margin-right: 10px;
}
.send-button {
  border-radius: 50px;
  background-color: #26caf3;
  color: #ffffff;
  border: none;
  padding: 8px 25px;
}
.chat-buttons {
  border: none;
  background-color: transparent;
  text-align: center;
  padding: 5px;
  border-radius: 100%;
}
.chat-buttons:hover {
  background-color: #77777770;
  opacity: 0.6;
}
.comment-doctor {
  margin-top: 20px;
}
.doctor-comment {
  width: 79%;
}
@media only screen and (max-width: 360px) {
  /* Styles */
  .doctor-name-presc {
    width: 100%;
  }
  .diagnosis-section {
    padding-left: 0px !important;
    padding-top: 20px;
  }
  .newul {
    padding-left: 0px !important;
  }
  .newli {
    width: 100%;
  }
  .newli2 {
    width: 100%;
  }
  .vertical-height {
    margin-top: 0px !important;
  }
  .doc-info {
    padding-top: 70px;
    padding-bottom: 30px;
  }
  .doc-info2 {
    padding-bottom: 30px;
  }
  .qr-code {
    /* width: 200px; */
    display: none;
  }
  .doctor-detail-presc {
    font-size: 15px !important;
  }
  .doctor-detail-value {
    font-size: 12px !important;
  }
  .new-list {
    width: 50%;
  }
}

/* .patient-info {
  display: flex;
  width: 15%;
  text-align: center;
} */
.newul {
  list-style-type: none;
  margin: auto;
  padding: 0;
  overflow: hidden;
  padding-left: 110px;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  /* background-color: #333333; */
  /* width: 75%; */
}
.newul2 {
  list-style-type: none;
  margin: auto;
  padding: 0;
  overflow: hidden;
  width: 90%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.newul3 {
  list-style-type: none;
  margin: auto;
  padding: 0;
  overflow: hidden;
  width: 90%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.newli {
  float: left;
  padding-left: 30px;
  padding-right: 30px;
}
.newli2 {
  float: right;
  padding-left: 30px;
  padding-right: 30px;
}

.newli a {
  display: block;
  color: white;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

.newli a:hover {
  background-color: #111111;
}
.newli2 a {
  display: block;
  color: white;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

.newli2 a:hover {
  background-color: #111111;
}
.vertical-height {
  margin-top: 50px;
}
.medicine-table {
  padding-bottom: 50px;
}
.content2 {
  background-color: #e5e5e5;
}
