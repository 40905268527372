table td,th{
    min-width: 150px;
    &.text-right{
        min-width: auto;
    }
}

.rdtPicker {
    width: 100%;
}

.rdtPicker table td,.rdtPicker table th{
    min-width: auto;
    width:auto;
}