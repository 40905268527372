$shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
  rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
$base-color: #22c15e;
$hover-base-color: hsl(143, 70%, 36%);

.ChatSupport {
  min-height: 100vh;
  width: 100vw;
  background-color: transparent;
  z-index: 99;
  position: absolute;
  pointer-events: none;
  font-size: 0.9rem;

  &__container {
    pointer-events: visible;
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    z-index: 100;

    &-wrap {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      transition: all 0.5s ease-out;
      overflow: hidden;

      &--open {
        max-height: 400px;
      }

      &--close {
        max-height: 0;
      }
    }
  }

  &__cross-btn {
    &:hover {
      background-color: $hover-base-color;
      transform: scale(1.05);
    }

    &:active {
      transform: scale(1);
    }
    margin-right: 5px;
    padding: 0.2em;
    background-color: $base-color;
    border-radius: 100%;
    border: none;
    color: white;
    margin-bottom: 0.5em;
  }

  &__content {
    display: flex;
    max-width: 320px;
    color: white;

    overflow: hidden;

    &-wrapper {
      border-radius: 10px;
      overflow: hidden;
      box-shadow: $shadow;
      margin-bottom: 1em;
      margin-right: 5px;
    }

    &-head {
      background-color: $base-color;
      text-align: center;
      padding: 0.6em 1.4em;
      display: flex;

      p {
        padding: 0;
        margin: 0;
      }
    }

    &-footer {
      background-color: #fff;
      text-align: center;
      padding: 0.6em 1.4em;
      display: flex;
      color: grey;

      p {
        padding: 0;
        margin: 0;
      }
    }
  }

  &__chat {
    padding: 0.6em 1em;
    background-color: #fff;
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: $shadow;
    margin-right: 5px;
    margin-bottom: 1em;
    color: rgb(65, 65, 65);

    &-input {
      border: none;
      flex-grow: 1;
    }
  }

  &__send-btn {
    color: $base-color;
    border: none;
    background-color: transparent;
  }

  &__cta-btn {
    &:hover {
      background-color: $hover-base-color;
      transform: scale(1.05);
    }

    &:active {
      transform: scale(1);
    }
    margin-right: 5px;
    padding: 0.7em 1.2em;
    color: white;
    background-color: $base-color;
    border: none;
    border-radius: 50px;
  }
}
